import React, { Component } from 'react'
import Floater from 'react-floater';
import {getCurrentWeek, getShiftedWeeks, getDateOfISOWeek, getCurrentYear, getQuarter } from '../../utils/dates'

const dateRangeForWeek = c => {
  const startDate = getDateOfISOWeek(c, getCurrentYear());
  var endDate = new Date(startDate);
  endDate.setDate(endDate.getDate() + 6);
  return `${startDate.toDateString().substring(0, 10)} - ${endDate.toDateString().substring(0, 10)}`;
}

export class GridHeader extends Component {

  constructor(props) {
    super(props);
    const now = new Date();
    this.state = {
      year: props.year ? props.year : now.getFullYear(),
      quarter: props.quarter ? props.quarter : getQuarter(now),
    }
  }
  
  render() {
    const firstCols = ['Objective', 'Key Result', 'Progress'];
    const { year, quarter } = this.state;
    const shiftedWeeks = getShiftedWeeks(year, quarter);
    const cols = firstCols.concat(shiftedWeeks);
    const currentWeek = getCurrentWeek();
    const currentYear = getCurrentYear();
    return (
      <thead>
        <tr key="pre-header">
          <th colspan="4">{year}-Q{quarter}</th>
          <th colspan={shiftedWeeks.length} id="headerWeek">Week</th>
        </tr>
        <tr key="header">{
        cols.map(c => (
          <th
            scope="col"
            key={c}
            colspan={c === 'Key Result' ? 2 : 1}
            style={c === 'Key Result' ? {width: '27%'} : {}}
            className={"column " + (((currentWeek === c) && (currentYear === year)) ? "current-week" :
                          (((currentWeek < c)  && (currentYear === year)) ? "future-week" : "past-week"))}
            >
          {firstCols.includes(c) ? c :
            <Floater
            content={dateRangeForWeek(c)}
            event="hover"
            placement="top"
            styles={{container: {
              textAlign: 'center'
            }}}
            >
            {c}
            </Floater>
          }
          </th>
        ))
      }</tr>
      </thead>
    )
  }
}

export default GridHeader

import React from 'react'
import Floater from 'react-floater';
import { withSnackbar } from 'notistack';

import KeyResultAddButton from './KeyResultAddButton';
import KeyResultList from './KeyResultList';
import ObjectiveDeleteButton from './ObjectiveDeleteButton';

const AUTO_HIDE_DURATION = 2000;

class ObjectiveForm extends React.Component {

  state = {
    floaterOpen: false
  }

  handleMouseEnter = () => {
    this.setState({floaterOpen: true})
  }

  handleMouseLeave = () => {
    this.setState({floaterOpen: false})
  }

  render() {
  const { floaterOpen } = this.state;
  const objInputId = 'input-obj-' + this.props.objective.id;
  const floaterContent = <div className="floater-text">
    <p>Objectives are your big-picture, <strong>qualitative</strong> goals for the quarter.</p>
    <p>Examples: <br />
      <em>"My business generates revenues"</em><br />
      <em>"Healthier than I've ever been"</em><br />
      <em>"Best quarter for my personal brand"</em><br />
    </p>
    <p>Objectives should be ambitious and <strong>motivating</strong>.</p>
  </div>

  return (
    <div className="card m-4 rounded-0">
      <div className="card-header">
        <Floater
          content={floaterContent}
          target={"#" + objInputId}
          event="hover"
          placement="left"
          open={floaterOpen} />
          <input
            id={objInputId}
            className='input-obj-title'
            defaultValue={this.props.objective.title}
            placeholder='Enter objective ...'
            onKeyDown={e => (e.keyCode===13) ? e.target.blur() : null}
            onBlur={async (e) => {
              this.props.editObjective({...this.props.objective, title: e.target.value})
                .then(() => {
                  this.props.enqueueSnackbar('Saved!', {
                    variant: 'success',
                    autoHideDuration: AUTO_HIDE_DURATION,
                  });
                  window.analytics.track('Edited Objective'); // Segment
                })
                .catch(() => {
                  this.props.enqueueSnackbar('Error!', {
                    variant: 'error',
                    autoHideDuration: AUTO_HIDE_DURATION,
                  });
                })
              
            }}
            onChange={this.props.handleChange}
            onMouseEnter={this.handleMouseEnter}
            onMouseLeave={this.handleMouseLeave}
          />
        <ObjectiveDeleteButton
          objective={this.props.objective}
          deleteObjective={this.props.deleteObjective}
        />
      </div>
      <div className="card-body kr-card">
      <ol>
        <KeyResultList
          objective={this.props.objective}
          keyResults={this.props.keyResults}
          editKeyResult={this.props.editKeyResult}
          deleteKeyResult={this.props.deleteKeyResult}
        /></ol>
        <KeyResultAddButton
          objective={this.props.objective}
          keyResults={this.props.keyResults}
          createKeyResult={this.props.createKeyResult}
        />
      </div>
    </div>
  )
}
}

export default withSnackbar(ObjectiveForm);
import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../actions';

import logo from '../img/okrs_logo_negative.png'

export class Header extends Component {
  
  constructor(props) {
    super(props);
    this.signOut = this.signOut.bind(this);
  }

  signOut() {
    this.props.signOut();
  }

  render() {
    return (
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark" key="navbar">
        
        <Link className="navbar-brand" to="/" key="logo-link" id="logo-link">
          <img src={logo} alt="OKRs.app" className="header-logo" key="logo"/>
        </Link>

        <div className="collapse navbar-collapse" key="navbar-div">
            {!this.props.isAuth ?
            <ul className="nav navbar-nav ml-auto" key="navbar-ul">
              <li className="nav-item" key="signup">
                <Link className="nav-link" to="/signup" key="signup-link" id="signup-link">Sign up</Link>
              </li>
              <li className="nav-item" key="signin">
                <Link className="nav-link" to="/signin" key="signin-link" id="signin-link">Login</Link>
              </li>
            </ul>
            :
            <ul className="nav navbar-nav ml-auto" key="navbar-ul">
              <li className="nav-item" key="dashboard">
                <Link className="nav-link" to='/' key="dashboard-link" id="dashboard-link">Dashboard</Link>
              </li>
              <li className="nav-item" key="set-okrs">
                <Link className="nav-link" to='/set-okrs' key="set-okrs-link" id="set-okrs-link">Set OKRs</Link>
              </li>
              <li className="nav-item" key="review">
                <Link className="nav-link" to="/review" key="review-link" id="review-link">Review</Link>
              </li>
              <li className="nav-item" key="buddies">
                <Link className="nav-link" to="/buddies" key="buddies-link" id="buddies-link">Buddies</Link>
              </li>
              <li className="nav-item" key="learn">
                <a className="nav-link" id="learn-link" key="learn-link" href="https://learn.okrs.app" target="_blank" rel="noopener noreferrer">Learn</a>
              </li>
              <li className="nav-item" key="signout">
                <Link className="nav-link" to="/signout" key="signout-link" id="signout-link" onClick={this.signOut}>Sign Out</Link>
              </li>
            </ul>
            }
        </div>
      </nav>
    )
  }
}

function mapStateToProps(state) {
  return {
    isAuth: state.auth.isAuthenticated
  };
}

export default connect(mapStateToProps, actions)(Header);

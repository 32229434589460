import axios from 'axios';
import { 
  SETOKRS_NEW_OBJECTIVE,
  SETOKRS_EDIT_OBJECTIVE,
  SETOKRS_DELETE_OBJECTIVE,
  SETOKRS_NEW_KEYRESULT,
  SETOKRS_DELETE_KEYRESULT,
  SETOKRS_GET_OBJECTIVES,
  SETOKRS_GET_KEYRESULTS,
  SETOKRS_EDIT_KEYRESULT,
  SETOKRS_GET_TYPEFORM_TOKEN,
} from './types';

export const createObjective = data => {
  return async dispatch => {
    try {
      const res = await axios.post('/api/objective', data);
      dispatch({
        type: SETOKRS_NEW_OBJECTIVE,
        payload: res.data
      })
    } catch(err) {
      console.error('err', err);
    }
  }
}

export const editObjective = data => {
  return async dispatch => {
    try {
      const res = await axios.post('/api/edit-objective', data);
      dispatch({
        type: SETOKRS_EDIT_OBJECTIVE,
        payload: res.data
      })
    } catch(err) {
      console.error('err', err);
    }
  }
}

export const getObjectives = data => {
  return async dispatch => {
    try {
      const res = await axios.get('/api/objectives');
      dispatch({
        type: SETOKRS_GET_OBJECTIVES,
        payload: res.data
      })
    } catch(err) {
      console.error('err', err);
    }
  }
}

export const deleteObjective = data => {
  return async dispatch => {
    try {
      const res = await axios.post('/api/delete-objective', data);
      dispatch({
        type: SETOKRS_DELETE_OBJECTIVE,
        payload: res.data
      })
    } catch(err) {
      console.error('err', err);
    }
  }
}

export const createKeyResult = data => {
  return async dispatch => {
    try {
      const res = await axios.post('/api/key-result', data);
      dispatch({
        type: SETOKRS_NEW_KEYRESULT,
        payload: res.data
      })
    } catch(err) {
      console.error('err', err);
    }
  }
}

export const deleteKeyResult = data => {
  return async dispatch => {
    try {
      const res = await axios.post('/api/delete-key-result', data); // TODO
      dispatch({
        type: SETOKRS_DELETE_KEYRESULT,
        payload: res.data
      })
    } catch(err) {
      console.error('err', err);
    }
  }
}

export const getKeyResults = data => {
  return async dispatch => {
    try {
      const res = await axios.get('/api/key-results');
      dispatch({
        type: SETOKRS_GET_KEYRESULTS,
        payload: res.data
      })
    } catch(err) {
      console.error('err', err);
    }
  }
}

export const editKeyResult = data => {
  return async dispatch => {
    try {
      const res = await axios.post('/api/edit-key-result', data);
      dispatch({
        type: SETOKRS_EDIT_KEYRESULT,
        payload: res.data
      })
    } catch(err) {
      console.error('err', err);
    }
  }
}

export const getTypeformToken = () => {
  return async dispatch => {
    try {
      const res = await axios.get('/api/create-typeform-token');
      dispatch({
        type: SETOKRS_GET_TYPEFORM_TOKEN,
        payload: res.data
      })
    } catch(err) {
      console.error('err', err);
    }
  }
}
import React from 'react'

import KeyResultForm from './KeyResultForm';

export default function KeyResultList(props) {
  
  return props.keyResults
          .filter(kr => kr.objective_id === props.objective.id)
          .sort((a, b) => a.id - b.id)
          .map(
            (kr) => <li key={kr.id}>
                      <KeyResultForm
                        keyResult={kr}
                        deleteKeyResult={props.deleteKeyResult}
                        editKeyResult={props.editKeyResult}
                      />
                    </li>
          );
}

import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter, Route} from 'react-router-dom';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';

import 'bootstrap/dist/css/bootstrap.css';

import App from './components/App/';

import Landing from './components/Landing';
import About from './components/StaticPages/About';
import Privacy from './components/StaticPages/Privacy';

import ForgotPassword from './components/Auth/ForgotPassword';
import Logout from './components/Auth/Logout';
import ResetPassword from './components/Auth/ResetPassword';
import SignUp from './components/Auth/SignUp';
import SignIn from './components/Auth/SignIn';

import Dashboard from './components/Dashboard/';
import PastQuarters from './components/Dashboard/PastQuarters';
import SetOkrs from './components/SetOkrs';
import BuddyList from './components/Dashboard/BuddyList';
import MagicSetOkrs from './components/SetOkrs/MagicSetOkrs';

import store from './store';

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
        <SnackbarProvider maxSnack={1}>
            <App>
                <Route exact path='/' component={Dashboard} />
                <Route exact path='/set-okrs' component={SetOkrs} />
                <Route exact path='/magic-set-okrs' component={MagicSetOkrs} />
                <Route exact path='/review' component={PastQuarters} />
                <Route exact path='/review/:year/:quarter' component={Dashboard} />
                <Route exact path='/view/:year/:quarter/:userId' component={Dashboard} />
                <Route exact path='/buddies' component={BuddyList} />
                <Route exact path='/landing' component={Landing} />
                <Route exact path='/signup' component={SignUp} />
                <Route exact path='/signin' component={SignIn} />
                <Route exact path='/logout' component={Logout} />
                <Route exact path='/forgot-password' component={ForgotPassword} />
                <Route exact path='/reset-password/:token' component={ResetPassword} />
                <Route exact path='/about' component={About} />
                <Route exact path='/privacy' component={Privacy} />
                <Route exact path='/learn' component={() => { 
                    window.location.href = 'https://learn.okrs.app';
                    return null;
                }}/>
            </App>
          </SnackbarProvider>
        </BrowserRouter>
    </Provider>,
    document.getElementById('root'));

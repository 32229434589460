import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Link } from 'react-router-dom';

import * as actions from '../../actions';
import CustomInput from '../CustomInput';

const validate = values => {
  const errors = {}
  if (!values.email) {
    errors.email = 'Required'
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,20}$/i.test(values.email)) {
    errors.email = 'Invalid email address'
  }
  if (!values.password) {
    errors.password = 'Required'
  } else if (values.password.length < 8) {
    errors.password = "Password should be at least 8 characters"
  }
  else if (values.password !== values.confirmpassword) {
    errors.confirmpassword = "Passwords don't match"
  }
  return errors
}

class SignUp extends Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    window.analytics.track('Viewed Signup'); // Segment
  }

  async onSubmit(formData) {
    await this.props.signUp(formData);
    if (!this.props.errorMessage) {
      window.analytics.identify(this.props.userId, {email: formData.email}); // Segment
      window.analytics.track('User Signup'); // Segment
      this.props.history.push('/');
    }
  }

  render() {
    const { handleSubmit } = this.props;
    return (
      <div className="container py-5">
      <div className="row">
        <div className="col-md-5 mx-auto">
        <div className="card rounded-0">
            <div className="card-header">
                <h3 className="mb-0">Sign Up</h3>
            </div>
            <div className="card-body">
              <form onSubmit={handleSubmit(this.onSubmit)}>
                <fieldset>
                  <Field
                    name="email"
                    type="text"
                    id="email"
                    label="Email"
                    placeholder="example@example.com"
                    component={ CustomInput } />
                </fieldset>
                <fieldset>
                  <Field
                    name="password"
                    type="password"
                    id="password"
                    label="Password"
                    placeholder="Enter password"
                    component={ CustomInput } />
                </fieldset>
                <fieldset>
                  <Field
                    name="confirmpassword"
                    type="password"
                    id="confirmpassword"
                    label="Confirm Password"
                    placeholder="Confirm password"
                    component={ CustomInput } />
                </fieldset>
                { this.props.errorMessage ? 
                <div className="alert alert-danger">
                  { this.props.errorMessage }
                </div> : null }
                <button type="submit" className="btn btn-primary signup-button">Sign Up</button>
                <p className="mt-3">
                  <em>By registering, you accept our&nbsp;
                    <Link to="/privacy" target="_blank" rel="noopener noreferrer">
                      privacy policy
                    </Link>.
                  </em>
                </p>
              </form>
              </div>
              </div>
        </div>
        </div>
        <p className="mt-4">Already have an account?&nbsp;
          <Link to="/signin">Log in</Link>
        </p>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    errorMessage: state.auth.errorMessage,
    userId: state.auth.userId,
  }
}

export default compose(
  connect(mapStateToProps, actions),
  reduxForm({ form: 'signup' , validate})
)(SignUp)
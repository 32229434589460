import React, { Component } from 'react'

import { trackEvent } from '../../utils/tracking';

const AUTO_HIDE_DURATION = 2000;

class CommentToolTip extends Component {

  handleCommentBlur(e) {
    const comment = e.target.value;
    const { kr_id, week } = this.props;
    const value = this.props.keyResultWeekObject ? this.props.keyResultWeekObject.value : null;
    this.props.editComment({kr_id, week, value, comment})
      .then(() => {
        trackEvent('Created Check-in Comment')
        this.props.enqueueSnackbar('Saved!', {
          variant: 'success',
          autoHideDuration: AUTO_HIDE_DURATION,
        });
      })
      .catch(() => {
        this.props.enqueueSnackbar('Error!', {
          variant: 'error',
          autoHideDuration: AUTO_HIDE_DURATION,
        });
      });
  }

  render() {
    const kr = this.props.keyResultWeekObject;
    const comment = kr ? kr.comment : null;
    const style = this.props.active ? {} : {display:'none'};
    const { cell_id } = this.props;
    return (
      <div
        key={cell_id + "-tool-tip-wrapper"}
        className='tool-tip-wrapper'
        style={style}>
          <div id={cell_id + "-comment"}>
            {this.props.isReview ?
              (comment ? <textarea
                readOnly
                id={cell_id + '-input'}
                className='comment-input'
                defaultValue={comment}
              /> : null) :
            <textarea
              id={cell_id + '-input'}
              className='comment-input'
              defaultValue={comment}
              placeholder='Add comment'
              onKeyDown={e => (e.keyCode===13) ? e.target.blur() : null}
              onBlur={this.handleCommentBlur.bind(this)}
            />
            }
          </div>
      </div>
    )
  }
}

export default CommentToolTip;

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';

import * as actions from '../../actions'
import { trackEvent } from '../../utils/tracking'
import { getCurrentQuarter, getCurrentYear } from '../../utils/dates'

import Loading from '../Loading';

export class PastQuarters extends Component {

  constructor(props) {
    super(props);
    this.state = {
      finishedLoading: false
    }
  }

  componentDidMount() {
    const currentQuarter = getCurrentQuarter();
    const currentYear = getCurrentYear();
    trackEvent('Viewed PastQuarters');

    this.props.getQuarters().then(() => {
      this.setState({
        finishedLoading: true,
        pastQuarters: this.props.quarters.filter(x => !(x.year === currentYear && x.quarter === currentQuarter))
      })
    })
  }

  render() {
    if(this.state.finishedLoading) {
      return (
        <main className="PastQuarters" >
          <h1>Review Past Quarters</h1>
            {this.state.pastQuarters.map((x) => <div>
              <Link to={`/review/${x.year}/${x.quarter}`}>
                <h4>{x.year}-Q{x.quarter}</h4>
              </Link>
            </div>)}
      </main>)
    } else {
      return <Loading />
    }
  }
}

function mapStateToProps(state) {
  return {
    quarters: state.dash.quarters,
    isAuth: state.auth.isAuthenticated
  }
}

export default connect(mapStateToProps, actions)(PastQuarters);

import React from 'react'

export default function SellingPoint(props) {
  return (
    <div className="col-lg-4">
    <div className="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
      <img className="img-fluid selling-point-icon mb-3" src={props.icon} alt="" />
      <h3>{props.title}</h3>
      <p className="lead mb-0">{props.text}</p>
    </div>
  </div>
  )
}

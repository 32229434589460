import React, { Component } from 'react'
import { ReactTypeformEmbed } from 'react-typeform-embed';
import { connect } from 'react-redux';
import * as actions from '../../actions';

import { Loading } from '../Loading';
import { getQuarter } from '../../utils/dates'

import './index.css';

export class MagicSetOkrs extends Component {

  constructor(props) {
    super(props);
    const now = new Date();
    this.state = {
      finishedLoading: false,
      year: now.getFullYear(),
      quarter: getQuarter(now),
    }
  }

  async componentDidMount() {
    await this.props.getTypeformToken();
    this.setState({
      ...this.state,
      finishedLoading: true
    })
    window.analytics.track('Viewed MagicSetOkrs'); // Segment
  }

  typeformUrl() {
    const { year, quarter } = this.state;
    const { typeformToken } = this.props;
    return `https://txd.typeform.com/to/FYRevx?token=${typeformToken}&year=${year}&quarter=${quarter}`;
  }

  render() {
    if (this.state.finishedLoading) {
      return (
        <div className="typeform-widget-container">
          <ReactTypeformEmbed 
            url={this.typeformUrl()}
            popup={false}
          />
        </div>
      )
    } else {
      return <Loading />
    }
  }
}

function mapStateToProps(state) {
  return {
    typeformToken: state.setokrs.typeformToken
  }
}

export default connect(mapStateToProps, actions)(MagicSetOkrs);
import {
  AUTH_SIGN_UP,
  AUTH_SIGN_IN,
  AUTH_SIGN_OUT,
  AUTH_ERROR,
  AUTH_CREATE_PASSWORD_RESET,
  AUTH_RESET_PASSWORD, 
  AUTH_INVITE_BUDDY,
  AUTH_GET_BUDDIES,
  AUTH_GET_USERS_YOU_BUDDY} from '../actions/types';

const DEFAULT_STATE = {
    isAuthenticated: false,
    token: null,
    errorMessage: '',
    buddies: [],
    usersYouBuddy: [],
}

export default (state = DEFAULT_STATE, action) => {
    switch(action.type) {
        case AUTH_SIGN_UP:
            return {...state, token: action.payload.token, userId: action.payload.userId, isAuthenticated: true, errorMessage: ''}
        case AUTH_SIGN_IN:
            return { ...state, token: action.payload.token, userId: action.payload.userId, isAuthenticated: true, errorMessage: '' }
        case AUTH_SIGN_OUT:
            return { ...state, token: '', isAuthenticated: false, errorMessage: '' }
        case AUTH_CREATE_PASSWORD_RESET:
            return { ...state, isAuthenticated: false, errorMessage: '' }
        case AUTH_RESET_PASSWORD:
            return { ...state, isAuthenticated: false, errorMessage: '' }
        case AUTH_INVITE_BUDDY:
            return { ...state, errorMessage: ''}
        case AUTH_GET_BUDDIES:
            return { ...state, buddies: action.payload }
        case AUTH_GET_USERS_YOU_BUDDY:
            return { ...state, usersYouBuddy: action.payload }
        case AUTH_ERROR:
            return {...state, errorMessage: action.payload}
        default:
            return state;
    }
};
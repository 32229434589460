import React from 'react'
import {getCurrentQuarter, getCurrentYear} from '../../utils/dates';
import { trackEvent } from '../../utils/tracking';

export default function ObjectiveAddButton(props) {
  return (
    <button 
        className="btn btn-primary btn-add-objective"
        onClick={() => {
          trackEvent('Clicked AddObjective'); // Segment
          props.createObjective({
            year: getCurrentYear(),
            quarter: getCurrentQuarter()
          })
          }}
    >+ Add Objective
    </button>
)
}

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import swal from 'sweetalert'
import { withSnackbar } from 'notistack'

import * as actions from '../../actions'
import Loading from '../Loading'
import { AUTO_HIDE_DURATION, INVITE_BUDDY_TEXT } from '../../utils/constants'
import { trackEvent } from '../../utils/tracking'
import { getQuarter } from '../../utils/dates'
import { isValidEmail } from '../../utils/validation'

export class BuddyList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      finishedLoading: false,
    }
  }

  componentDidMount() {
    this.props.getBuddies().then(() => {
      this.props.getUsersYouBuddy().then(() => {
        trackEvent('Viewed BuddyList');
        this.setState({
          ...this.state,
          finishedLoading: true,
        })
      })
    })
  }

  handleInviteBuddySubmit = (value) => {
    if (!value) {
      return;
    }
    if (!isValidEmail(value)) {
      swal('Not a valid email');
      return;
    }
    const now = new Date();
    const year = now.getFullYear();
    const quarter = getQuarter(now);
    this.props.inviteBuddy({
      buddyEmail: value,
      year,
      quarter,
    }).then(() => {
        this.props.getBuddies().then(() => {
          this.props.enqueueSnackbar('Sent invite!', {
            variant: 'success',
            autoHideDuration: AUTO_HIDE_DURATION,
          });
        })
        trackEvent('Invited Buddy');
      })
      .catch(() => {
        this.props.enqueueSnackbar('Error!', {
          variant: 'error',
          autoHideDuration: AUTO_HIDE_DURATION,
        });
      });
  }

  render() {
    const { buddies, usersYouBuddy } = this.props;
    if (this.state.finishedLoading) {
      return (
        <main className="BuddyList" >
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <h2>Your Buddies</h2>
                <p><em>Your buddies can view your OKRs, to help motivate you.</em></p>
                {buddies.map((x) => <div><h5>{x.name || x.email} [{x.year}-Q{x.quarter}]</h5></div>)}
                <div className="mt-4">
                  <button
                  className="btn btn-primary btn-invite-buddy"
                  onClick={() => {
                    trackEvent('Clicked InviteBuddy'); // Segment
                    swal({
                      text: INVITE_BUDDY_TEXT,
                      content: {
                        element: "input",
                        attributes: {
                          placeholder: "Enter your friend's email address ...",
                          type: "email",
                        },
                      },
                      button: {
                        text: 'Invite'
                      },
                    }).then(this.handleInviteBuddySubmit.bind(this));
                  }
                }
              >👋Invite buddy
              </button></div>
              </div>
              <div className="col-lg-6">
                <h2>You Are Buddying</h2>
                <p><em>People who have invited you to become their buddy.</em></p>
                {usersYouBuddy.map((x) => <div>
                  <Link to={`/view/${x.year}/${x.quarter}/${x.user_id}`}>
                    <h5>{x.name || x.email} [{x.year}-Q{x.quarter}]</h5>
                  </Link>
                </div>)}
              </div>
            </div>
          </div>
        </main>
      )
    } else {
      return <Loading />
    }
  }
}

function mapStateToProps(state) {
  return {
    buddies: state.auth.buddies,
    usersYouBuddy: state.auth.usersYouBuddy,
    isAuth: state.auth.isAuthenticated
  }
}

export default withSnackbar(connect(mapStateToProps, actions)(BuddyList));
import swal from '@sweetalert/with-react';

const _arrayToObject = (array, keyField) =>
   array.reduce((obj, item) => {
     obj[item[keyField]] = item.value
     return obj
   }, {})

export const transformData = (items) => items.map(item => {
    if (item && item.check_ins && item.check_ins[0] !== null) {
        return {...item, ..._arrayToObject(item.check_ins, "week")}
    } else {
        return null;
    }
});

export const validateNumber = (e) => {
  if (isNaN(e.target.value)) {
    swal("This field must be a number!");
    e.target.value = null;
    return false;
  } 
  return true;
}

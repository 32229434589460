import React from 'react'
import { trackEvent } from '../../utils/tracking';

export default class About extends React.Component {

  componentDidMount() {
    trackEvent('Viewed About');
  }

  render() {
    return (
      <div className="info-page">
        <h1 className="info-header">
          Our mission is to help freelancers, startup founders, and anyone with ambitions reach their personal goals.
        </h1>
        <p className="info-paragraph">
          OKRs.app was created to make it simpler for you to set, track, and follow up on your personal goals. <br />
          Our philosophy is based on combining a <strong>simple product</strong> with an <strong>effective methodology</strong>.<br />
          We believe in transparency, and our privacy policy is available <a href="/privacy">here</a>.
        </p>
        
      </div>
    )
}
}

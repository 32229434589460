import {
  SETOKRS_NEW_OBJECTIVE, 
  SETOKRS_EDIT_OBJECTIVE,
  SETOKRS_DELETE_OBJECTIVE,
  SETOKRS_NEW_KEYRESULT,
  SETOKRS_DELETE_KEYRESULT,
  SETOKRS_GET_OBJECTIVES,
  SETOKRS_GET_KEYRESULTS,
  SETOKRS_EDIT_KEYRESULT,
  SETOKRS_GET_TYPEFORM_TOKEN,
} from '../actions/types';

const DEFAULT_STATE = {
  objectives: [],
  keyResults: [],
}

export default (state = DEFAULT_STATE, action) => {
  switch(action.type) {
    case SETOKRS_NEW_OBJECTIVE:
      return { ...state, objectives: state.objectives.concat([action.payload]) }
    case SETOKRS_EDIT_OBJECTIVE:
      return { ...state, 
        objectives: state.objectives.filter(x => x.id !== action.payload.id).concat([action.payload]),
       }
    case SETOKRS_DELETE_OBJECTIVE:
      return { ...state, 
        objectives: state.objectives.filter(x => x.id !== action.payload.id),
        keyResults: state.keyResults.filter(x => x.objective_id !== action.payload.id),
       }
    case SETOKRS_NEW_KEYRESULT:
      return { ...state, keyResults: state.keyResults.concat([action.payload]) }
    case SETOKRS_DELETE_KEYRESULT:
      return { ...state, keyResults: state.keyResults.filter(x => x.id !== action.payload.id) }
    case SETOKRS_GET_OBJECTIVES:
      return { ...state,  objectives: action.payload }
    case SETOKRS_GET_KEYRESULTS:
      return { ...state,  keyResults: action.payload }
    case SETOKRS_EDIT_KEYRESULT:
      return { ...state, 
        keyResults: state.keyResults.filter(x => x.id !== action.payload.id).concat([action.payload]),
       }
    case SETOKRS_GET_TYPEFORM_TOKEN:
      return { ...state,  typeformToken: action.payload.token }
    default:
      return state
  }
}
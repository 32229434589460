import axios from 'axios';
import { 
  AUTH_SIGN_UP, 
  AUTH_SIGN_OUT, 
  AUTH_SIGN_IN, 
  AUTH_ERROR,
  AUTH_RESET_PASSWORD,
  AUTH_CREATE_PASSWORD_RESET,
  AUTH_INVITE_BUDDY,
  AUTH_GET_BUDDIES,
  AUTH_GET_USERS_YOU_BUDDY,
} from './types';
import { ERROR_MESSAGES } from './constants';

export const signUp = data => {
  return async dispatch => {
    try {
      const res = await axios.post('/api/auth/signup', data);

      dispatch({
        type: AUTH_SIGN_UP,
        payload: res.data
      });

      localStorage.setItem('JWT_TOKEN', res.data.token);
      axios.defaults.headers.common['Authorization'] = res.data.token;
    } catch(err) {
      dispatch({
        type: AUTH_ERROR,
        payload: ERROR_MESSAGES.EMAIL_ALREADY_IN_USE
      })
    }
  };
}

export const signIn = data => {
  return async dispatch => {
    try {
      const res = await axios.post('/api/auth/signin', data);

      dispatch({
        type: AUTH_SIGN_IN,
        payload: res.data
      });

      localStorage.setItem('JWT_TOKEN', res.data.token);
      axios.defaults.headers.common['Authorization'] = res.data.token;
    } catch(err) {
      dispatch({
        type: AUTH_ERROR,
        payload: ERROR_MESSAGES.INVALID_COMBINATION
      })
    }
  };
}

export const signOut = () => {
  return dispatch => {
    localStorage.removeItem('JWT_TOKEN');
    axios.defaults.headers.common['Authorization'] = '';

    dispatch({
      type: AUTH_SIGN_OUT,
      payload: ''
    })
  };
}

export const resetPassword = data => {
  return async dispatch => {
    try {
      await axios.post('/api/auth/reset-password', data);
      dispatch({
        type: AUTH_RESET_PASSWORD
      });

    } catch(err) {
      dispatch({
        type: AUTH_ERROR,
        payload: ERROR_MESSAGES.INVALID_TOKEN
      })
    }
  };
}

export const createPasswordReset = data => {
  return async dispatch => {
    try {
      await axios.post('/api/auth/create-password-reset', data);
      dispatch({
        type: AUTH_CREATE_PASSWORD_RESET
      });

    } catch(err) {
      dispatch({
        type: AUTH_ERROR,
        payload: ERROR_MESSAGES.INVALID_TOKEN
      })
    }
  };
}

export const inviteBuddy = data => {
  return async dispatch => {
    try {
      const res = await axios.post('/api/auth/invite-buddy', data);
      dispatch({
        type: AUTH_INVITE_BUDDY,
        payload: res.data
      })
    } catch(err) {
      dispatch({
        type: AUTH_ERROR,
        payload: ERROR_MESSAGES.INVITE_BUDDY_FAILED
      })
    }
  }
};

export const getBuddies = () => {
  return async dispatch => {
    try {
      const res = await axios.get('/api/auth/get-buddies');
      dispatch({
        type: AUTH_GET_BUDDIES,
        payload: res.data,
      })

    } catch(err) {
      /* istanbul ignore next */
      console.error('err', err);
    }
  }
};

export const getUsersYouBuddy = () => {
  return async dispatch => {
    try {
      const res = await axios.get('/api/auth/get-users-you-buddy');
      dispatch({
        type: AUTH_GET_USERS_YOU_BUDDY,
        payload: res.data,
      })

    } catch(err) {
    /* istanbul ignore next */
    console.error('err', err);
    }
  }
};
import React from 'react'
import { trackEvent } from '../../utils/tracking';

export default function KeyResultDeleteButton(props) {
  return (
    <button 
      className="btn btn-danger btn-sm"
      style={{float: 'right'}}
      value={props.keyResult.id}
      onClick={() => {
        trackEvent('Clicked DeleteKeyResult'); // Segment
        props.deleteKeyResult(props.keyResult)
      }}
    >-</button>
  )
}

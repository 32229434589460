import React, { Component } from 'react'
import './index.css'
import SellingPoint from './SellingPoint';
import SignUpButton from './SignUpButton';
import Testimonial from './Testimonial';

import { trackEvent } from '../../utils/tracking';

import testimonialAna from '../../img/testimonial-ana.jpeg'
import testimonialJoe from '../../img/testimonial-joe.jpeg'
import testimonialGuy from '../../img/testimonial-guy.jpeg'

import iconSimple from '../../img/simple.png'
import iconEffective from '../../img/effective.png'
import iconRewarding from '../../img/rewarding.png'

import illoFreelancer from '../../img/freelancers.gif'
import illoStartupFounder from '../../img/startups.gif'
import illoAnyone from '../../img/anyone.gif'

import demoGif from '../../img/okrs_landing_animation.gif'

export class Landing extends Component {

  componentDidMount() {
    trackEvent('Viewed Landing');
  }

  render() {
    return (
      <div id="landing">
        <header className="masthead text-center mt-4">
          <div className="overlay"></div>
          <div className="container">
            <div className="row">
              <div className="col-lg-6 order-lg-2">
                <img className="demo-gif" src={demoGif} alt="" />
              </div>
              <div className="col-lg-6 order-lg-1">
                <h1 className="mb-4">Reach your personal goals with OKRs</h1>
                <p className="mb-4 landing-lead-in">
                Objectives and Key Results (OKRs) are used by some of the most successful companies in the world. 
                Should you accept any less? 
                <br />
                No prior knowledge needed - we'll get you started with OKRs today.
                </p>
                <SignUpButton isCenter={false} />
              </div>
            </div>
          </div>
        </header>

  <section className="features-icons bg-light text-center">
    <div className="container">
      <div className="row">
        <SellingPoint
          title="Simple"
          text="No experience with Objectives and Key Results (OKRs) required. We'll set you up with everything you need."
          icon={iconSimple}
        />
        <SellingPoint
          title="Effective"
          text="A simple dashboard and helpful reminders make it easy to check in on your OKRs every week."
          icon={iconEffective}
        />
        <SellingPoint
          title="Rewarding"
          text="Making progress every week feels great! You'll get into the habit quickly when you see the results."
          icon={iconRewarding}
        />
      </div>
    </div>
  </section>

  <section className="showcase">
    <div className="container-fluid p-0">
      <div className="row no-gutters">

        <div className="col-lg-6 order-lg-2 text-white showcase-img">
          <img className="illo" src={illoFreelancer} alt="" />
        </div>
        <div className="col-lg-6 order-lg-1 my-auto showcase-text">
          <h2>For Freelancers</h2>
          <p className="lead mb-0">It can be hard to set goals when you have no-one to help you. And even harder to follow up! 
          With OKRs you get a simple framework that you can use to keep track of your goals every week.</p>
        </div>
      </div>
      <div className="row no-gutters">
        <div className="col-lg-6 text-white showcase-img">
          <img className="illo" src={illoStartupFounder} alt="" />
        </div>
        <div className="col-lg-6 my-auto showcase-text">
          <h2>Startup Founders</h2>
          <p className="lead mb-0">You have ambitious goals, but are you using the right framework? 
          OKRs can help you get specific on your goals, and ultimately help you make your vision a reality.</p>
        </div>
      </div>
      <div className="row no-gutters">
        <div className="col-lg-6 order-lg-2 text-white showcase-img">
          <img className="illo" src={illoAnyone} alt="" />
        </div>
        <div className="col-lg-6 order-lg-1 my-auto showcase-text">
          <h2>Anyone!</h2>
          <p className="lead mb-0">OKRs are a surprisingly powerful tool. 
          Even for your personal life, you can set objectives for your health, career, and just about anything! 
          Forget New Year's resolutions - adopt OKRs instead and you'll see actual results.
          </p>
        </div>
      </div>
    </div>
  </section>

  <section className="testimonials text-center bg-light">
    <div className="container">
      <h2 className="mb-5">What users say about OKRs.app</h2>
      <div className="row">
        <Testimonial
          name="Ana, Illustrator"
          imageUrl={testimonialAna}
          text='"This app has helped me reach my personal goals over the last year - I highly recommend it!"'
        />
        <Testimonial
          name="Joe, Product Manager"
          imageUrl={testimonialJoe}
          text="&quot;I honestly don't know how I managed my personal goals before I found this app. I can't wait for the team version.&quot;"
        />
        <Testimonial
          name="Guy, Data Scientist"
          imageUrl={testimonialGuy}
          text="&quot;I didn't really know how to set personal goals in a useful way. With OKRs.app I can even follow up on them weekly!&quot;"
        />
      </div>
    </div>
  </section>

  <section className="call-to-action text-white text-center">
    <div className="overlay"></div>
    <div className="container">
      <div className="row">
        <div className="col-xl-9 mx-auto">
          <h2 className="mb-4 text-white">Ready to get started with OKRs?</h2>
        </div>
        <SignUpButton isCenter={true}/>
      </div>
    </div>
  </section>
  <section>
  <div className="overlay"></div>
    <div className="container">
      <div className="row">
        <div className="col-xl-9 mx-auto m-4">
        <a className="landing-what-are-okrs" href="https://learn.okrs.app/what-are-okrs/" target="_blank" rel="noopener noreferrer">What are OKRs?</a>
        </div>
      </div>
    </div>
  </section>
  </div>
    )
  }
}

export default Landing

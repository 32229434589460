import React, { Component } from 'react';
import { reduxForm, Field, SubmissionError} from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Link } from 'react-router-dom';

import * as actions from '../../actions';
import CustomInput from '../CustomInput';

const validate = values => {
  const errors = {}
  if (!values.password) {
    errors.password = 'Required'
  } else if (values.password.length < 8) {
    errors.password = "Password should be at least 8 characters"
  }
  else if (values.password !== values.confirmpassword) {
    errors.confirmpassword = "Passwords don't match"
  }
  return errors
}


class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    window.analytics.track('Viewed ResetPassword'); // Segment
  }

  validateConfirmPassword(formData) {
    return formData.password === formData.confirmpassword;
  }

  async onSubmit(formData) {
    if (!this.validateConfirmPassword(formData)) {
      console.log('Validation error');
      throw new SubmissionError({ _error: "Passwords don't match!" })
    }
    const token = this.props.match.params.token;
    formData.resetToken = token;
    await this.props.resetPassword(formData);
    if (!this.props.errorMessage) {
      this.props.history.push('/signin');
    }
  }

  render() {
    const { handleSubmit } = this.props;
    return (
      <div className="container py-5">
      <div className="row">
        <div className="col-md-5 mx-auto">
        <div className="card rounded-0">
            <div className="card-header">
                <h3 className="mb-0">Reset Password</h3>
            </div>
            <div className="card-body">
          <form onSubmit={handleSubmit(this.onSubmit)}>
            <fieldset>
                  <Field
                    name="password"
                    type="password"
                    id="password"
                    label="New Password"
                    placeholder="Enter password"
                    component={ CustomInput } />
                </fieldset>
                <fieldset>
                  <Field
                    name="confirmpassword"
                    type="password"
                    id="confirmpassword"
                    label="Confirm Password"
                    placeholder="Confirm password"
                    component={ CustomInput } />
                </fieldset>

            { this.props.errorMessage ? 
            <div className="alert alert-danger">
              { this.props.errorMessage }
            </div> : null }

            <button type="submit" className="btn btn-primary signup-button">Sign In</button>
          </form>
        </div>
        </div>
        </div>
      </div>
      <p className="mt-4">Don't have an account?&nbsp;
          <Link to="/signup">Sign up</Link>
        </p>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    errorMessage: state.auth.errorMessage
  }
}

export default compose(
  connect(mapStateToProps, actions),
  reduxForm({ form: 'reset-password', validate })
)(ResetPassword)
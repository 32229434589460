import {
  DASHBOARD_GET_OKRS,
  DASHBOARD_NEW_CHECKIN,
  DASHBOARD_DELETE_CHECKIN,
  DASHBOARD_EDIT_COMMENT,
  DASHBOARD_GET_QUARTERS
} from '../actions/types';

const DEFAULT_STATE = {
  data: [],
  quarters: [],
}

export default (state = DEFAULT_STATE, action) => {
  switch(action.type) {
    case DASHBOARD_GET_OKRS:
      return { ...state, data: action.payload }
    case DASHBOARD_NEW_CHECKIN:
      return { ...state, data: action.payload }
    case DASHBOARD_DELETE_CHECKIN:
      return { ...state, data: action.payload }
    case DASHBOARD_EDIT_COMMENT:
        return { ...state, data: action.payload }
    case DASHBOARD_GET_QUARTERS:
        return { ...state, quarters: action.payload }
    default:
      return state
  }
}
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import './index.css';
import * as actions from '../../actions';
import { Loading } from '../Loading';
import ObjectiveAddButton from './ObjectiveAddButton';
import ObjectiveForm from './ObjectiveForm';

export class SetOkrs extends Component {

  constructor(props) {
    super(props);
    this.state = {
      finishedLoading: false
    }
  }

  async componentDidMount() {
    await this.props.getObjectives();
    await this.props.getKeyResults();
    this.setState({
      ...this.state,
      finishedLoading: true
    })
    
    window.analytics.track('Viewed SetOkrs'); // Segment
  }

  render() {
    if (!this.state.finishedLoading) {
        return <main className="Dashboard" ><Loading /></main>
    } else {
    return (
      <div className="container setokrs-container mt-4">
        {this.props.objectives.sort((a, b) => a.id - b.id).map(
            obj => 
              <ObjectiveForm 
                key={obj.id}
                objective={obj}
                keyResults={this.props.keyResults}
                deleteObjective={this.props.deleteObjective}
                editObjective={this.props.editObjective}
                createKeyResult={this.props.createKeyResult}
                editKeyResult={this.props.editKeyResult}
                deleteKeyResult={this.props.deleteKeyResult}
              />
          )
        }
        {this.props.objectives.length < 5 &&
          <ObjectiveAddButton 
            objectives={this.props.objectives}
            createObjective={this.props.createObjective}
          />
        }
        {this.props.keyResults.length > 0 &&
          <React.Fragment>
            <br /><br />
            <Link to="/">
              <button className="btn btn-primary btn-done">
                Done
              </button>
            </Link>
          </React.Fragment>
        }
        <p className="new-to-okrs-magic-tool m-4">New to OKRs? Try our <Link to="/magic-set-okrs">magic tool</Link> to set your first OKRs.</p>
      </div>
    )
  }
}
}

function mapStateToProps(state) {
    return {
      objectives: state.setokrs.objectives,
      keyResults: state.setokrs.keyResults,
    }
  }
  
export default connect(mapStateToProps, actions)(SetOkrs);

import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import axios from 'axios';
import reducers from './reducers';

const jwtToken = localStorage.getItem('JWT_TOKEN');
axios.defaults.headers.common['Authorization'] = jwtToken;

const store = createStore(reducers, {
    auth: {
        token: jwtToken,
        isAuthenticated: jwtToken ? true : false
    }
}, applyMiddleware(thunk));

export default store;
import React from 'react'
import Floater from 'react-floater';

export default class ProgressBar extends React.Component {

  state = {
    floaterOpen: false
  }

  handleMouseEnter = () => {
    this.setState({floaterOpen: true})
  }

  handleMouseLeave = () => {
    this.setState({floaterOpen: false})
  }

  render () {
    const { floaterOpen } = this.state;
    const progressBarId = "progress-bar-" + this.props.krId
    const { currentValue, score, target } = this.props;
    const quarterProgressPct = this.props.quarterProgress * 100.0;
    const scoreFormatted = isNaN(this.props.score) ? "0%" : `${this.props.score.toFixed(0)}%`;
    const floaterContent = <div>
      <strong>{currentValue} out of {target} ({scoreFormatted})</strong><br />
      {currentValue === 0 ? "🐣Not started" : 
        (score > quarterProgressPct ? "🔥On track" : "🐌Catch up!")}
      </div>;
    return (
      <div
        key={progressBarId}
        id={progressBarId}
        className="progress-bar-container"
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        <Floater
          content={floaterContent}
          target={"#" + progressBarId}
          event="hover"
          placement="left"
          open={floaterOpen} />
        <div
          style={{
            width: scoreFormatted,
            height: '100%',
            backgroundColor: this.props.score > 66 ? '#00a180'
              : this.props.score > 33 ? '#17d2a5'
              : '#9eecbe',
            borderRadius: '2px',
            transition: 'all .2s ease-out'
          }}
        />
      </div>
      )
  }
}

import React from 'react'
import PropTypes from 'prop-types'

import Cell from './Cell'
import ProgressBar from './ProgressBar'
import { getKeyResultWeekObject } from './helpers'
import { calculateCurrentValue, currentValueToScore } from '../../utils/scoring'
import { WEEKS, getQuarterProgress, getQuarter } from '../../utils/dates'

export default function Row(props) {
  const { row, data } = props;
  const now = new Date();
  const year = props.year ? props.year : now.getFullYear();
  const quarter = props.quarter ? props.quarter : getQuarter(now);
  const quarterProgress = getQuarterProgress();
  const currentValue = calculateCurrentValue(row);
  const score = currentValueToScore(calculateCurrentValue(row), row.kr_target);
  return (
    <tr key={props.kr_id}>
        {row.kr_rank === '1' ? 
          <td
            style={{
              verticalAlign : 'middle', 
              textAlign: 'center',
              width: '12%'
            }}
            rowSpan={row.kr_count}
          >
            {row.obj_title}
          </td> : null}
        <td style={{textAlign: 'right'}}>{row.kr_target}</td>
        <td style={{textAlign: 'left'}}>
          {row.kr_link ? <a href={row.kr_link} target="_blank" rel="noopener noreferrer">{row.kr_title}</a> : row.kr_title}
        </td>
        <td style={{height: '10px', 'width': '20px'}}>
            <ProgressBar
              key={`progress-bar-${row.kr_id}`}
              krId={row.kr_id}
              target={row.kr_target}
              currentValue={currentValue}
              score={score}
              quarterProgress={quarterProgress}
            />
        </td>
        {WEEKS.map(week => {
          return <Cell
                  key={`cell-${row.kr_id}-${week}`}
                  week={week}
                  kr_id={row.kr_id} 
                  year={year}
                  quarter={quarter}
                  keyResultWeekObject={getKeyResultWeekObject(data, row.kr_id, week)}
                  createCheckin={props.createCheckin}
                  deleteCheckin={props.deleteCheckin}
                  editComment={props.editComment}
                  enqueueSnackbar={props.enqueueSnackbar}
                  isReview={props.isReview}
                  />
        })}
      </tr>
  )
}

Row.propTypes = {
  row: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  createCheckin: PropTypes.func.isRequired,
  deleteCheckin: PropTypes.func.isRequired,
  editComment: PropTypes.func.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
};

import React, { Component } from 'react'

export class Footer extends Component {
  render() {
    return (
      <footer>
        <p>Made with <span aria-label="fire" role="img">🔥</span>in Hong Kong. &copy; 2019 <a href="/about">Nevik Limited</a>.</p>
      </footer>
    )
  }
}

export default Footer

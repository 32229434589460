export const NUM_WEEKS = 13
export const WEEKS = [...Array(NUM_WEEKS).keys()].map(i => (i + 1));

export const getQuarter = date => {
  return Math.ceil((date.getMonth() + 1) / 3);
}

export const getCurrentYear = () => {
  return new Date().getFullYear();
}

export const getCurrentQuarter = () => {
  return getQuarter(new Date());
}

export const getCurrentWeek = () => {
return getWeekNumber(new Date());
}

export const getQuarterProgress = () => {
  const currentWeek = getCurrentWeek();
  const startWeekOfCurrentQuarter = getStartWeekOfCurrentQuarter();
  const endWeekOfCurrentQuarter = getEndWeekOfCurrentQuarter();
  const numWeeks = endWeekOfCurrentQuarter - startWeekOfCurrentQuarter;
  return (currentWeek - startWeekOfCurrentQuarter) / numWeeks;
}


// Returns the ISO week of the date.
export const getWeekNumber = date => {
  date.setHours(0, 0, 0, 0);
  // Thursday in current week decides the year.
  date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
  // January 4 is always in week 1.
  var week1 = new Date(date.getFullYear(), 0, 4);
  // Adjust to Thursday in week 1 and count number of weeks from date to week1.
  return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000
                        - 3 + (week1.getDay() + 6) % 7) / 7);
}

export const getStartDateOfQuarter = (year, quarter) => {
  const month = (quarter * 3) - 3;
  return new Date(Date.UTC(year, month, 1));
}

export const getEndDateOfQuarter = (year, quarter) => {
  const month = (quarter * 3) - 1;
  const day = 28; // cut-off here to avoid very short weeks
  return new Date(year, month, day, 0, 0, 0, 0);
}

export const getStartWeekOfCurrentQuarter = () => {
  const now = new Date();
  const quarter = getQuarter(now);
  const date = getStartDateOfQuarter(now.getFullYear(), quarter);
  return getWeekNumber(date);
}

export const getStartWeekOfYearQuarter = (year, quarter) => {
  const date = getStartDateOfQuarter(year, quarter);
  return getWeekNumber(date);
}

export const getEndWeekOfCurrentQuarter = () => {
  const now = new Date();
  const quarter = getQuarter(now);
  const date = getEndDateOfQuarter(now.getFullYear(), quarter);
  return getWeekNumber(date);
}

export const convertToCurrentCalendarWeek = w => {
  return parseInt(w) + getStartWeekOfCurrentQuarter() - 1;
}

export const convertToCalendarWeek = (week, year, quarter) => {
  const startWeek = getStartWeekOfYearQuarter(year, quarter);
  return week + startWeek - 1
}

export const getShiftedWeeks = (year, quarter) => {
  const startWeek = getStartWeekOfYearQuarter(year, quarter);
  return WEEKS.map(w => (w + startWeek - 1));
}

export function getDateOfISOWeek(w, y) {
  var simple = new Date(y, 0, 1 + (w - 1) * 7);
  var dow = simple.getDay();
  var ISOweekStart = simple;
  if (dow <= 4)
      ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
  else
      ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
  return ISOweekStart;
}
import React from 'react'
import { trackEvent } from '../../utils/tracking';

export default class Privacy extends React.Component {

  componentDidMount() {
    trackEvent('Viewed Privacy');
  }

  render() {
    return (
      <div className="info-page">
        <h1 className="info-header">
          Privacy policy
        </h1>
        <p className="info-body">
          OKRs.app is a service by <a href="https://opencorporates.com/companies/hk/2753523" target="_blank" rel="noopener noreferrer">Nevik Limited</a>, a Hong Kong company.
          <br /><br />
          We have a few fundamental principles that we follow:
        </p>
          <ol className="info-body">
            <li>We don’t ask you for personal information unless we truly need it.</li>
            <li>We don’t share your personal information with anyone except to develop our products, protect our rights, or comply with the law.</li>
            <li>We don’t store personal information on our servers unless required for the on-going operation of our service.</li>
          </ol>
        <p className="info-body">
          It is our policy to respect your privacy regarding any information we may collect while operating our service.
        </p>
  
        <h3>Website Visitors</h3>
        <p className="info-body">
        Like most website operators, we collect non-personally-identifying information of the sort that web browsers and servers typically make available, such as the browser type, language preference, referring site, and the date and time of each visitor request. 
        Our purpose in collecting non-personally identifying information is to better understand how visitors use our website.
        From time to time, we may release non-personally-identifying information in the aggregate, e.g., by publishing a report on trends in the usage of our website.
        We also collect potentially personally-identifying information like email addresses for logged-in users.
        </p>
  
        <h3>Gathering of Personally-Identifying Information</h3>
        <p className="info-body">
        Certain visitors to our website choose to interact with OKRs.app in ways that require us to gather personally-identifying information.
        The amount and type of information that OKRs.app gathers depends on the nature of the interaction.
        For example, we ask visitors who sign up to become users of our service to provide an email address.
        We do this simply because it is the most convenient way to persist a user account over time, while having the possibility to communicate with you as a user (via email).
        When signing up to OKRs.app as a user, you are automatically enrolled into our email communication, but you can unsubscribe at any time by clicking the "Unsubscribe" button in our emails.
        When users are logged in, we also save the information given to us as necessary for the service to provide value.
        This information includes (but is not limited to) objectives, key results, and status updates on these.
        OKRs.app collects information only insofar as is necessary or appropriate to fulfill the purpose of the visitor’s interaction with OKRs.app.
        OKRs.app does not disclose personally-identifying information other than as described below.
        Visitors can always refuse to supply personally-identifying information, with the caveat that it may prevent them from engaging in certain website-related activities.
        </p>
  
        <h3>Aggregated Statistics</h3>
        <p className="info-body">
        OKRs.app may collect statistics about the behavior of visitors and users of its websites.
        For instance, OKRs.app may monitor the most popular objectives or key results created on OKRs.app.
        OKRs.app may display this information publicly or provide it to others.
        However, OKRs.app does not disclose personally-identifying information other than as described below.
        </p>
  
        <h3>Protection of Certain Personally-Identifying Information</h3>
        <p className="info-body">
        OKRs.app discloses potentially personally-identifying and personally-identifying information only to those of its employees, contractors and affiliated organizations that (i) need to know that information in order to process it on OKRs.app behalf or to provide services available at OKRs.app websites, and (ii) that have agreed not to disclose it to others.
        Some of those employees, contractors and affiliated organizations may be located outside of your home country; by using OKRs.app, you consent to the transfer of such information to them.
        OKRs.app will not rent or sell potentially personally-identifying and personally-identifying information to anyone.
        Other than to its employees, contractors and affiliated organizations, as described above, OKRs.app discloses potentially personally-identifying and personally-identifying information only in response to a subpoena, court order or other governmental request, or when OKRs.app believes in good faith that disclosure is reasonably necessary to protect the property or rights of OKRs.app, third parties or the public at large.
        If you are a registered user of an OKRs.app website and have supplied your email address, OKRs.app may occasionally send you an email to tell you about new features, solicit your feedback, or just keep you up to date with what’s going on with OKRs.app and our products.
        We primarily use our various product blogs to communicate this type of information, so we expect to keep this type of email to a minimum.
        If you send us a request (for example via a support email or via one of our feedback mechanisms), we reserve the right to publish it in order to help us clarify or respond to your request or to help us support other users.
        OKRs.app takes all measures reasonably necessary to protect against the unauthorized access, use, alteration or destruction of potentially personally-identifying and personally-identifying information.
        </p>
  
        <h3>Cookies</h3>
        <p className="info-body">
        A cookie is a string of information that a website stores on a visitor’s computer, and that the visitor’s browser provides to the website each time the visitor returns.
        OKRs.app uses cookies to help OKRs.app identify and track visitors, their usage of OKRs.app website, and their website access preferences.
        OKRs.app visitors who do not wish to have cookies placed on their computers should set their browsers to refuse cookies before using OKRs.app's websites,
        with the drawback that certain features of OKRs.app websites may not function properly without the aid of cookies.
        </p>
        
        <h3>3rd Party Providers</h3>
        <p className="info-body">
        The following is a list of trusted 3rd party providers and products that we use to collect, store, and process user data:
        </p>
        <ul className="info-body">
          <li><a href="https://amplitude.com/" target="_blank" rel="noopener noreferrer">Amplitude</a></li>
          <li><a href="https://crisp.chat/" target="_blank" rel="noopener noreferrer">Crisp</a></li>
          <li><a href="https://google.com/analytics/" target="_blank" rel="noopener noreferrer">Google Analytics</a></li>
          <li><a href="https://tagmanager.google.com/" target="_blank" rel="noopener noreferrer">Google Tag Manager</a></li>
          <li><a href="https://mailgun.com/" target="_blank" rel="noopener noreferrer">Mailgun</a></li>
          <li><a href="https://typeform.com/" target="_blank" rel="noopener noreferrer">Typeform</a></li>
        </ul>
        <p className="info-body">
        OKRs.app may add or subtract 3rd party providers based on business needs, and in OKRs.app's sole discretion.
        We encourage visitors to frequently check this page for any changes to list of providers.
        </p>
  
        <h3>Business Transfers</h3>
        <p className="info-body">
        If OKRs.app, Nevik Limited, or substantially all of its assets were acquired, or in the unlikely event that Nevik Limited goes out of business or enters bankruptcy, user information could be one of the assets that is transferred or acquired by a third party.
        You acknowledge that such transfers may occur, and that any acquirer of OKRs.app or Nevik Limited continue to use your personal information as set forth in this policy.
        </p>
  
        <h3>Data Controller and Data Protection Officers</h3>
        <p className="info-body">
        The Data Controller for the processing of personal data is: <br /><br />
        Nevik Limited<br />
        9th Floor, Amtel Building<br />
        148 Des Voeux Road Central<br />
        Hong Kong<br />
        sv@nevik.ai<br /><br />
  
        For further information about your data protection rights, you may contact Nevik Limited's Data Protection Officer at the (email) address above.
        </p>
  
        <h3>Privacy Policy Changes</h3>
        <p className="info-body">
        Although most changes are likely to be minor, OKRs.app may change its Privacy Policy from time to time, and in OKRs.app's sole discretion.
        OKRs.app encourages visitors to frequently check this page for any changes to its Privacy Policy.
        Your continued use of this site after any change in this Privacy Policy will constitute your acceptance of such change.
        </p>
        
      </div>
    )
  }
}

import React from 'react'
import { Link } from 'react-router-dom'

export default function SignUpButton(props) {
  const cName = props.isCenter ? "col-xl-4 mx-auto" : "col-xl-4 button-container";
  return (
    <div className={cName}>
      <Link to="/signup" className="signup-button">
        <button type="submit" className="btn btn-block btn-lg btn-primary btn-signup">Sign Up</button>
      </Link>
    </div>
  )
}

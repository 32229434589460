import React from 'react'
import { trackEvent } from '../../utils/tracking';

export default function ObjectiveDeleteButton(props) {
  return (
    <button 
      className="btn btn-danger btn-sm" 
      style={{float: 'right'}} 
      value={props.objective.id}
      onClick={() => {
        trackEvent('Clicked DeleteObjective'); // Segment
        props.deleteObjective(props.objective)
      }}
    >-</button>
  )
}

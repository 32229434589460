import { NUM_WEEKS } from './dates'

const getCheckInForWeek = (checkIns, week) => {
  return checkIns.filter(ci => ci.week === week);
}

const calculateScoreWrapper = (krObject, numWeeks, updateRule) => {
  let currentValue = 0;
  [...Array(numWeeks).keys()].forEach( i => {
      if(krObject.check_ins) {
        const cellInArray = getCheckInForWeek(krObject.check_ins, i + 1);
        if(cellInArray.length > 0) {
          let cellValue = cellInArray[0]['value'];
          currentValue = updateRule(currentValue, cellValue);
        }
      }
  });
  return Math.max(0, currentValue);
}

const calculateSumScore = (krObject, numWeeks) => calculateScoreWrapper(
  krObject, numWeeks, (currentValue, cellValue) => currentValue + (cellValue || 0));

const calculateLatestScore = (krObject, numWeeks) => calculateScoreWrapper(
  krObject, numWeeks, (currentValue, cellValue) => (cellValue || currentValue));

export const SCORE_METHOD = {
  'sum': calculateSumScore,
  'latest': calculateLatestScore
}

export const calculateCurrentValue = (row) => {
  return row.kr_score_method ? SCORE_METHOD[row.kr_score_method](row, NUM_WEEKS) : 0.0;
}

export const currentValueToScore = (currentValue, target) => {
  return Math.min(100.0, (currentValue / target) * 100);
}

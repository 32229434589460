import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { trackEvent } from '../../utils/tracking';

export class NoObjectivesMessage extends Component {

  componentDidMount() {
    trackEvent('Viewed NoObjectives');
  }

  render() {
    const { isReview, isBuddy, hasBuddies } = this.props;
    return (
    <div className="d-flex justify-content-center mt-5" style={{padding: 250}}>
        <div className="justify-content-center">
          {(isBuddy) && <p>No OKRs for you to see here!<br /><Link to="/">Back</Link></p>}
          {(!isBuddy) && <p>You have 0 objectives and key results for this quarter.</p>}
          {(!isReview) && <p>Start by&nbsp;<Link to="/set-okrs">setting some!</Link></p>}
          {(!isReview && hasBuddies) && <p>... or check out your&nbsp;<Link to="/buddies">buddy's OKRs</Link>.</p>}
          {(isReview && !isBuddy) && <p><Link to="/review">Review other quarters</Link></p>}
        </div>
    </div>
    )
  }
}

export default NoObjectivesMessage

import React from 'react'
import Floater from 'react-floater'
import { withSnackbar } from 'notistack'
import swal from '@sweetalert/with-react'

import { ReactComponent as SettingsIcon } from '../../img/settings-icon.svg'
import { validateNumber } from '../../utils'
import { isValidURL } from '../../utils/validation'
import { trackEvent } from '../../utils/tracking'
import KeyResultDeleteButton from './KeyResultDeleteButton'
import SettingsInput from './SettingsInput'

const AUTO_HIDE_DURATION = 2000;

class KeyResultForm extends React.Component {

  state = {
    floaterOpen: false
  }

  handleMouseEnter = () => {
    this.setState({floaterOpen: true})
  }

  handleMouseLeave = () => {
    this.setState({floaterOpen: false})
  }

  handleSubmitSettings = (value) => {
    if (!value) {
      return;
    }
    const {score_method, link} = value;
    if (link && !isValidURL(link)) {
      swal('Not a valid link!');
      return;
    }
    this.props.editKeyResult({...this.props.keyResult, score_method, link})
      .then(() => {
        this.props.enqueueSnackbar('Saved!', {
          variant: 'success',
          autoHideDuration: AUTO_HIDE_DURATION,
        });
        trackEvent('Edited KeyResult Link');
      })
      .catch(() => {
        this.props.enqueueSnackbar('Error!', {
          variant: 'error',
          autoHideDuration: AUTO_HIDE_DURATION,
        });
      });
  }

  render() {
    const { floaterOpen } = this.state;
    const kr = this.props.keyResult;
    const krInputId = 'input-kr-' + this.props.keyResult.id;
    const floaterContent = <div className="floater-text">
      <p>Key results are specific, <strong>quantitative</strong> sub-goals linked to your objectives.</p>
      <p>Examples: <br />
        <em>"$3,000 billed to clients"</em><br />
        <em>"45 visits to the gym"</em><br />
        <em>"2,000 new Instagram followers"</em><br />
      </p>
      <p>Key results should be actionable and <strong>measurable</strong>.</p>
    </div>

  return (
    <div className="key-result row" key={kr.id}>
      <Floater
      content={floaterContent}
      target={"#" + krInputId}
      event="hover"
      placement="left"
      open={floaterOpen} />
      <input
        id={krInputId}
        className='input-kr-target'
        defaultValue={kr.target}
        placeholder={'X'}
        onKeyDown={e => (e.keyCode===13) ? e.target.blur() : null}
        onBlur={async (e) => {
          if (validateNumber(e)) {
            this.props.editKeyResult({...kr, target: e.target.value})
              .then(() => {
                this.props.enqueueSnackbar('Saved!', {
                  variant: 'success',
                  autoHideDuration: AUTO_HIDE_DURATION,
                });
                trackEvent('Edited KeyResult Target');
              })
              .catch(() => {
                this.props.enqueueSnackbar('Error!', {
                  variant: 'error',
                  autoHideDuration: AUTO_HIDE_DURATION,
                });
              });
          }
          return null;
        }}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        />
        <input
        className='input-kr-title'
        defaultValue={kr.title}
        placeholder={'things accomplished ...'}
        onKeyDown={e => (e.keyCode===13) ? e.target.blur() : null}
        onBlur={async (e) => {
          this.props.editKeyResult({...kr, title: e.target.value})
              .then(() => {
                this.props.enqueueSnackbar('Saved!', {
                  variant: 'success',
                  autoHideDuration: AUTO_HIDE_DURATION,
                });
                trackEvent('Edited KeyResult Title');
              })
              .catch(() => {
                this.props.enqueueSnackbar('Error!', {
                  variant: 'error',
                  autoHideDuration: AUTO_HIDE_DURATION,
                });
              });
        }}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      />
      <span
        className="kr-link-edit-button"
        onClick={() => {
          swal({
            content: <SettingsInput score_method={kr.score_method} link={kr.link} />,
            buttons: {
              confirm: {
                text: 'Save',
                value: {
                  score_method: kr.score_method, 
                  link: kr.link
                }
              }
            }}).then(this.handleSubmitSettings.bind(this));
        }}
      >
        <SettingsIcon className="settings-icon" />
      </span>
      <KeyResultDeleteButton
          keyResult={kr}
          deleteKeyResult={this.props.deleteKeyResult}
      />
    </div>
  )
}
}

export default withSnackbar(KeyResultForm);
import React, { Component } from 'react'

export class Loading extends Component {
  render() {
    return (
    <div className="d-flex justify-content-center mt-5" style={{padding: 250}}>
        <div className="spinner-border text-primary justify-content-center" role="status">
          <span className="sr-only">Loading...</span>
        </div>
    </div>
    )
  }
}

export default Loading

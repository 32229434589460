export const AUTH_SIGN_UP = 'AUTH_SIGN_UP';
export const AUTH_SIGN_OUT = 'AUTH_SIGN_OUT';
export const AUTH_SIGN_IN = 'AUTH_SIGN_IN';
export const AUTH_CREATE_PASSWORD_RESET = 'AUTH_CREATE_PASSWORD_RESET';
export const AUTH_RESET_PASSWORD = 'AUTH_RESET_PASSWORD';
export const AUTH_ERROR = 'AUTH_ERROR';
export const AUTH_INVITE_BUDDY = 'AUTH_INVITE_BUDDY';
export const AUTH_GET_BUDDIES = 'AUTH_GET_BUDDIES';
export const AUTH_GET_USERS_YOU_BUDDY = 'AUTH_GET_USERS_YOU_BUDDY';
export const DASHBOARD_GET_DATA = 'DASHBOARD_GET_DATA';
export const DASHBOARD_GET_OKRS = 'DASHBOARD_GET_OKRS';
export const DASHBOARD_NEW_CHECKIN = 'DASHBOARD_NEW_CHECKIN';
export const DASHBOARD_DELETE_CHECKIN = 'DASHBOARD_DELETE_CHECKIN';
export const DASHBOARD_EDIT_COMMENT = 'DASHBOARD_EDIT_COMMENT';
export const DASHBOARD_GET_QUARTERS = 'DASHBOARD_GET_QUARTERS';
export const SETOKRS_NEW_OBJECTIVE = 'SETOKRS_NEW_OBJECTIVE';
export const SETOKRS_EDIT_OBJECTIVE = 'SETOKRS_EDIT_OBJECTIVE';
export const SETOKRS_DELETE_OBJECTIVE = 'SETOKRS_DELETE_OBJECTIVE';
export const SETOKRS_NEW_KEYRESULT = 'SETOKRS_NEW_KEYRESULT';
export const SETOKRS_DELETE_KEYRESULT = 'SETOKRS_DELETE_KEYRESULT';
export const SETOKRS_EDIT_KEYRESULT = 'SETOKRS_EDIT_KEYRESULT';
export const SETOKRS_GET_OBJECTIVES = 'SETOKRS_GET_OBJECTIVES';
export const SETOKRS_GET_KEYRESULTS = 'SETOKRS_GET_KEYRESULTS';
export const SETOKRS_GET_TYPEFORM_TOKEN = 'SETOKRS_GET_TYPEFORM_TOKEN';
import axios from 'axios';
import { 
  DASHBOARD_GET_OKRS, 
  DASHBOARD_NEW_CHECKIN,
  DASHBOARD_DELETE_CHECKIN,
  DASHBOARD_EDIT_COMMENT,
  DASHBOARD_GET_QUARTERS,
} from './types';

export const getOkrs = () => {
  return async dispatch => {
    try {
      const res = await axios.get('/api/');

      dispatch({
        type: DASHBOARD_GET_OKRS,
        payload: res.data
      })
      
      return res; // returning this so we can know if the user was authenticated
    } catch(err) {
      /* istanbul ignore next */
      console.error('err', err);
    }
  }
}

export const getOkrsForYearQuarter = (year, quarter, userId) => {
  return async dispatch => {
    try {
      const res = await axios.post('/api/', {year, quarter, userId});

      dispatch({
        type: DASHBOARD_GET_OKRS,
        payload: res.data
      })
      
      return res; // returning this so we can know if the user was authenticated
    } catch(err) {
      /* istanbul ignore next */
      console.error('err', err);
    }
  }
}

export const createCheckin = data => {
  return async dispatch => {
    try {
      const res = await axios.post('/api/checkin', data);
      dispatch({
        type: DASHBOARD_NEW_CHECKIN,
        payload: res.data
      })
    } catch(err) {
      /* istanbul ignore next */
      console.error('err', err);
    }
  }
}

export const deleteCheckin = data => {
  return async dispatch => {
    try {
      const res = await axios.post('/api/delete-checkin', data);
      dispatch({
        type: DASHBOARD_DELETE_CHECKIN,
        payload: res.data
      })
    } catch(err) {
      /* istanbul ignore next */
      console.error('err', err);
    }
  }
}

export const editComment = data => {
  return async dispatch => {
    try {
      const res = await axios.post('/api/edit-comment', data);
      dispatch({
        type: DASHBOARD_EDIT_COMMENT,
        payload: res.data
      })
    } catch(err) {
      /* istanbul ignore next */
      console.error('err', err);
    }
  }
}

export const getQuarters = () => {
  return async dispatch => {
    try {
      const res = await axios.get('/api/quarters');
      dispatch({
        type: DASHBOARD_GET_QUARTERS,
        payload: res.data
      })
    } catch(err) {
      /* istanbul ignore next */
      console.error('err', err);
    }
  }
}
 import React, { Component } from 'react'
import './index.css'
import { Landing } from '../Landing'
import { Loading } from '../Loading'
import { connect } from 'react-redux'
import { withSnackbar } from 'notistack'
import swal from 'sweetalert';

import * as actions from '../../actions'
import { AUTO_HIDE_DURATION } from '../../utils/constants'
import Row from './Row'
import NoObjectivesMessage from './NoObjectivesMessage'
import GridHeader from './GridHeader'

import { trackEvent } from '../../utils/tracking'
import { getQuarter } from '../../utils/dates'
import { isValidEmail } from '../../utils/validation'

export class Dashboard extends Component {

  constructor(props) {
    super(props);
    const now = new Date();
    const isReview = this.props.match && this.props.match.params && this.props.match.params.quarter;
    const isBuddy = isReview && this.props.match.params.userId;
    if (isReview) {
      this.state = {
        year: parseInt(this.props.match.params.year, 10),
        quarter: parseInt(this.props.match.params.quarter, 10),
        isReview: true,
        isBuddy: false,
        finishedLoading: false,
      }
    } else {
      this.state = {
        year: now.getFullYear(),
        quarter: getQuarter(now),
        isReview: false,
        isBuddy: false,
        finishedLoading: false,
      }
    }
    if (isBuddy) {
      this.state = {
        ...this.state,
        isBuddy: true,
        userId: parseInt(this.props.match.params.userId, 10),
      }
    }
  }
  
  handleInviteBuddySubmit = (value) => {
    if (!value) {
      return;
    }
    if (!isValidEmail(value)) {
      swal('Not a valid email');
      return;
    }
    this.props.inviteBuddy({
      buddyEmail: value,
      year: this.state.year,
      quarter: this.state.quarter
    }).then(() => {
        this.props.enqueueSnackbar('Sent invite!', {
          variant: 'success',
          autoHideDuration: AUTO_HIDE_DURATION,
        });
        trackEvent('Invited Buddy');
      })
      .catch(() => {
        this.props.enqueueSnackbar('Error!', {
          variant: 'error',
          autoHideDuration: AUTO_HIDE_DURATION,
        });
      });
  }

  componentDidMount() {
    const { year, quarter, userId } = this.state;
    if (this.state.isBuddy) {
      this.props.getOkrsForYearQuarter(year, quarter, userId).then((res) => {
        if (!res) {
          // TODO: handle what happens when user does not have access
          this.props.history.push('/');
        }
        this.setState({
          ...this.state,
          finishedLoading: true,
        })
        trackEvent('Viewed BuddyView');
      })
    }
    else if (this.props.isAuth) {
      this.props.getOkrsForYearQuarter(year, quarter).then((res) => {
        if (!res) { // no response means user is not really authenticated
          this.props.signOut();
          this.props.history.push('/');
        }
        this.props.getUsersYouBuddy().then(() => {
          this.setState({
            ...this.state,
            finishedLoading: true,
          })
        })
        if (this.props.data && this.props.data.length > 0) {
          if (this.state.isReview) {
            trackEvent('Viewed Review');
          } else {
            trackEvent('Viewed Dashboard');
          }
        }
      });
    } else {
      this.setState({
        ...this.state,
        finishedLoading: true
      })
    }
  }

  render() {
    if (!this.props.isAuth) {
      return (
        <main className="Dashboard" >
          <Landing />
        </main>
      )
    }
    else if (!this.state.finishedLoading) { // not yet loaded
      return <main className="Dashboard" ><Loading /></main>
    } else if (this.props.data.length === 0) { // loaded, but no OKRs set yet
        return (
          <main className="Dashboard" >
            <NoObjectivesMessage
              isReview={this.state.isReview}
              isBuddy={this.state.isBuddy}
              hasBuddies={this.props.usersYouBuddy && this.props.usersYouBuddy.length > 0}
            />
          </main>
        )
      } else {
        return (
          <main className="Dashboard" >
            <table className="table table-bordered table-hover">
              <GridHeader
                year={this.state.year}
                quarter={this.state.quarter}
              />
              <tbody>{
                this.props.data.map(row => (
                  <Row
                    key={row.kr_id}
                    row={row}
                    data={this.props.data}
                    year={this.state.year}
                    quarter={this.state.quarter}
                    createCheckin={this.props.createCheckin}
                    deleteCheckin={this.props.deleteCheckin}
                    editComment={this.props.editComment}
                    enqueueSnackbar={this.props.enqueueSnackbar}
                    isReview={this.state.isReview}
                  />)
                )}
              </tbody>
            </table>
            {!this.state.isBuddy && <div className="mt-4">
              <button
                  className="btn btn-primary btn-invite-buddy"
                  onClick={() => {
                    trackEvent('Clicked InviteBuddy'); // Segment
                    swal({
                      text: "Invite a buddy who can see your progress. Users with OKR buddies are more likely to reach their goals.",
                      content: {
                        element: "input",
                        attributes: {
                          placeholder: "Enter your friend's email address ...",
                          type: "email",
                        },
                      },
                      button: {
                        text: 'Invite'
                      },
                    }).then(this.handleInviteBuddySubmit.bind(this));
                  }
                }
              >👋Invite buddy
              </button>
            </div>
            }
          </main>
        )
      }
  }
}

function mapStateToProps(state) {
  return {
    data: state.dash.data,
    usersYouBuddy: state.auth.usersYouBuddy,
    isAuth: state.auth.isAuthenticated
  }
}

export default withSnackbar(connect(mapStateToProps, actions)(Dashboard));
import React, { Component } from 'react'
import swal from '@sweetalert/with-react';
import PropTypes from 'prop-types';

import CommentToolTip from './CommentToolTip'
import {getCurrentWeek, convertToCalendarWeek, getCurrentYear} from '../../utils/dates';

const AUTO_HIDE_DURATION = 2000;

export default class Cell extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isTooltipActive: false
    }
  }

  showTooltip() {
    this.setState({isTooltipActive: true})
  }
  hideTooltip() {
      this.setState({isTooltipActive: false})
  }

  handleBlur(e) {
    const currentValueInCell = e.target.innerHTML;
    const value = parseFloat(currentValueInCell);
    const kr_id = this.props.kr_id;
    const week = this.props.week;
    if (!isNaN(value)) {
      this.props.createCheckin({kr_id, week, value})
        .then(() => {
          this.props.enqueueSnackbar('Saved!', {
            variant: 'success',
            autoHideDuration: AUTO_HIDE_DURATION,
          });
          window.analytics.track('Created Check-in'); // Segment
        })
        .catch(() => {
          this.props.enqueueSnackbar('Error!', {
            variant: 'error',
            autoHideDuration: AUTO_HIDE_DURATION,
          });
        });
    } 
    else if (currentValueInCell === '' ||
             currentValueInCell === null ||
             currentValueInCell === '<br>') {
      this.props.deleteCheckin({kr_id, week})
        .then(() => {
          this.props.enqueueSnackbar('Saved!', {
            variant: 'success',
            autoHideDuration: AUTO_HIDE_DURATION,
          });
          window.analytics.track('Deleted Check-in'); // Segment
        })
        .catch(() => {
          this.props.enqueueSnackbar('Error!', {
            variant: 'error',
            autoHideDuration: AUTO_HIDE_DURATION,
          });
        });
    }
    else {
      swal('This field must be a number!');
      e.target.innerHTML = null;
    }
  }

  render() {
    const { week, kr_id, year, quarter, isReview } = this.props;
    const cell_id = "cell-" + kr_id + week;
    const kr = this.props.keyResultWeekObject;
    const value = kr ? kr.value : null;
    const currentYear = getCurrentYear();
    return (
      <td
        key={cell_id}
        className={((currentYear === year) && (getCurrentWeek() === convertToCalendarWeek(week, year, quarter))) 
          ? "week-cell current-week" : "week-cell"}
        onMouseEnter={this.showTooltip.bind(this)}
        onMouseLeave={this.hideTooltip.bind(this)}
        style={
            (kr && kr.comment) ? {
              backgroundImage: "linear-gradient(225deg, #17D2A5, #17D2A5 8px, transparent 8px, transparent)"
            } : {}
          }
        >
          {isReview ? <div
          id={cell_id}
          className="row-content"
          dangerouslySetInnerHTML={{
            __html: value
          }}/> :
        <div
          id={cell_id}
          className="row-content"
          contentEditable
          suppressContentEditableWarning
          onKeyDown={e => (e.keyCode===13) ? e.target.blur() : null}
          onBlur={this.handleBlur.bind(this)}
          dangerouslySetInnerHTML={{
            __html: value
          }}
        ></div>}
        <CommentToolTip
          isReview={isReview}
          key={cell_id + '-comment-tool-tip'}
          active={this.state.isTooltipActive}
          kr_id={kr_id}
          week={week}
          cell_id={cell_id}
          keyResultWeekObject={kr}
          editComment={this.props.editComment}
          enqueueSnackbar={this.props.enqueueSnackbar}
        />
      </td>
    )
  }
}

Cell.propTypes = {
  kr_id: PropTypes.number.isRequired,
  week: PropTypes.number.isRequired,
  keyResultWeekObject: PropTypes.object,
  editComment: PropTypes.func.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  createCheckin: PropTypes.func.isRequired
};

//export default withSnackbar(Cell);
import React from 'react'
import { trackEvent } from '../../utils/tracking';

export default function KeyResultAddButton(props) {
  return (
    <div className="add-key-result-button">
      <button
          className="btn btn-secondary btn-sm m-2"
          onClick={() => {
            trackEvent('Clicked AddKeyResult'); // Segment
            props.createKeyResult({
              objective_id: props.objective.id
            })
          }
        }
      >+
      </button>
      <span>Add Key Result</span>
    </div>
  )
}

import React, { Component } from 'react'
import swal from '@sweetalert/with-react';

export class SettingsInput extends Component {

  constructor(props) {
    super(props);
    this.state = {
      score_method: this.props.score_method || 'sum',
      link: this.props.link || null,
    };
  }

  changeScoreMethod(e) {
    let score_method = e.target.value;
    this.setState({
      ...this.state,
      score_method,
    }, () => { // setState is async
      swal.setActionValue({confirm: this.state});
    });
  }

  changeLink(e) {
    let link = e.target.value;
    this.setState({
      ...this.state,
      link,
    }, () => { // setState is async
      swal.setActionValue({confirm: this.state});
    });
  }

  render() {
    return (
      <div>
        <div className="settings-input-score-method">
          <h5>How do you want to check-in?</h5>
          <div className="settings-input-radio-wrapper">
            <input
              type="radio"
              name="WeeklyProgress"
              value="sum"
              checked={this.state.score_method === 'sum'} 
              onChange={this.changeScoreMethod.bind(this)}
            />&nbsp;Weekly Progress
            <br />
            <input
              type="radio"
              name="TotalProgress"
              value="latest"
              checked={this.state.score_method === 'latest'} 
              onChange={this.changeScoreMethod.bind(this)}
            />&nbsp;Total Progress
          </div>
          <p className="settings-helper-text">
            <em>Select "Weekly Progress" if you will add your incremental progress per week. Otherwise, "Total Progress".</em>
          </p>
        </div>
        <div>
          <h5>Add a useful link</h5>
          <input
            className="settings-input-link"
            placeHolder="Enter your link ..."
            value={this.state.link}
            onChange={this.changeLink.bind(this)}
          />
          <p className="settings-helper-text">
            <em>To help you keep track of your key result, we'll add this link to your dashboard.</em>
          </p>
        </div>
      </div>
    )
  }
}

export default SettingsInput

import React from 'react';
import './App.css';
import Footer from '../Footer'
import Header from '../Header'

export default (props) => {
  return (
    <div className="App">
      <Header />
          { props.children }
      <Footer />
    </div>
  );
};
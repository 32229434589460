import React from 'react'

export default function Testimonial(props) {
  return (
    <div className="col-lg-4">
      <div className="testimonial-item mx-auto mb-5 mb-lg-0">
        <img className="img-fluid rounded-circle mb-3" src={props.imageUrl} alt="" />
        <h5>{props.name}</h5>
        <p className="font-weight-light mb-0">{props.text}</p>
      </div>
    </div>
  )
}

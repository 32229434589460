import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Link } from 'react-router-dom';

import * as actions from '../../actions';
import CustomInput from '../CustomInput';

const validate = values => {
  const errors = {}
  if (!values.email) {
    errors.email = 'Required'
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,20}$/i.test(values.email)) {
    errors.email = 'Invalid email address'
  }
  if (!values.password) {
    errors.password = 'Required'
  }
  return errors
}

class SignIn extends Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
  }
  
  componentDidMount() {
    window.analytics.track('Viewed Login'); // Segment
  }

  async onSubmit(formData) {
    await this.props.signIn(formData);
    if (!this.props.errorMessage) {
      window.analytics.identify(this.props.userId, {email: formData.email}); // Segment
      window.analytics.track('User Login'); // Segment
      this.props.history.push('/');
    }
  }

  render() {
    const { handleSubmit } = this.props;
    return (
      <div className="container py-5">
      <div className="row">
        <div className="col-md-5 mx-auto">
        <div className="card rounded-0">
            <div className="card-header">
                <h3 className="mb-0">Login</h3>
            </div>
            <div className="card-body">
          <form onSubmit={handleSubmit(this.onSubmit)}>
            <fieldset>
              <Field
                name="email"
                type="text"
                id="email"
                label="Email"
                placeholder="example@example.com"
                component={ CustomInput } />
            </fieldset>
            <fieldset>
              <Field
                name="password"
                type="password"
                id="password"
                label="Password"
                placeholder="Enter password"
                component={ CustomInput } />
            </fieldset>

            { this.props.errorMessage ? 
            <div className="alert alert-danger">
              { this.props.errorMessage }
            </div> : null }

            <button type="submit" className="btn btn-primary signup-button">Sign In</button>
          </form>
        </div>
        </div>
        </div>
      </div>
      <p className="mt-4">Don't have an account?&nbsp;
        <Link to="/signup">Sign up</Link>
      </p>
      <p className="mt-4">Forgot password?&nbsp;
        <Link to="/forgot-password">Reset</Link>
      </p>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    errorMessage: state.auth.errorMessage,
    userId: state.auth.userId,
  }
}

export default compose(
  connect(mapStateToProps, actions),
  reduxForm({ form: 'signin', validate })
)(SignIn)
import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'redux';

import * as actions from '../../actions';
import CustomInput from '../CustomInput';

const validate = values => {
  const errors = {}
  if (!values.email) {
    errors.email = 'Required'
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,20}$/i.test(values.email)) {
    errors.email = 'Invalid email address'
  }
  return errors
}


export class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = { sentEmail: false };
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    window.analytics.track('Viewed ForgotPassword'); // Segment
  }

  async onSubmit(formData) {
    await this.props.createPasswordReset(formData);
    if (!this.props.errorMessage) {
      this.setState({sentEmail: true});
    }
  }

  render() {
    const { handleSubmit } = this.props;
    if (this.state.sentEmail) {
      return <div className="forgot-password-sent-email mt-5">If you have an account, we just sent you an email! Check your inbox.</div>
    } else {
      return (
        <div className="container py-5">
        <div className="row">
          <div className="col-md-5 mx-auto">
          <div className="card rounded-0">
              <div className="card-header">
                  <h3 className="mb-0">Forgot Password</h3>
              </div>
              <div className="card-body">
            <form onSubmit={handleSubmit(this.onSubmit)}>
              <fieldset>
                <Field
                  name="email"
                  type="text"
                  id="email"
                  label="Email"
                  placeholder="example@example.com"
                  component={ CustomInput } />
              </fieldset>
              { this.props.errorMessage ? 
              <div className="alert alert-danger">
                { this.props.errorMessage }
              </div> : null }
              <button type="submit" className="btn btn-primary signup-button">Reset Password</button>
            </form>
          </div>
          </div>
          </div>
        </div>
        </div>
      ); 
    }
  }
}

function mapStateToProps(state) {
  return {
    errorMessage: state.auth.errorMessage
  }
}

export default compose(
  connect(mapStateToProps, actions),
  reduxForm({ form: 'forgot-password', validate })
)(ForgotPassword)